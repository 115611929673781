import React, { useEffect } from 'react';
import {
    Container,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Grid,
    Button,
    Menu,
    Box,
    Autocomplete
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MoreTimeOutlined, RestartAltOutlined } from '@mui/icons-material';
import useFilter, { typeDates, quickStatus, dateRanges } from '../../hooks/useFilter';

const ProposalsFilter = () => {
    const {
        filter,
        anchorEl,
        proposalsFileNum,
        collaboratorManagers,
        states,
        handleFilterChange,
        handleFilterQuickStatusChange,
        setDateRange,
        handleOpenMenu,
        handleCloseMenu,
        restartFilter,
    } = useFilter();

    useEffect(() => {
        restartFilter()
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth={"xl"}>
                <Grid container spacing={1} mt={2} mb={3}>
                    <Grid item xs={12} sm={1.25}>
                        <Autocomplete
                            options={proposalsFileNum}
                            value={filter.fileNum}
                            onChange={(_event, newValue) => handleFilterChange({ target: { name: 'fileNum', value: newValue } })}
                            renderInput={(params) => <TextField {...params} label="Nº propuesta" />}
                            isOptionEqualToValue={(option, value) => option === value || value === null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Box display="flex" alignItems="center" width="100%">
                                <DesktopDatePicker
                                    label="Fecha inicio"
                                    value={filter.initDate}
                                    onChange={(date) => handleFilterChange({ target: { name: 'initDate', value: date } })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    format="dd/MM/yyyy"
                                />
                                <DesktopDatePicker
                                    label="Fecha fin"
                                    value={filter.endDate}
                                    onChange={(date) => handleFilterChange({ target: { name: 'endDate', value: date } })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    format="dd/MM/yyyy"
                                />
                                <Button
                                    onClick={handleOpenMenu}
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: 56, minWidth: 0 }}
                                >
                                    <MoreTimeOutlined />
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    {dateRanges.map((dateRange) => (
                                        <MenuItem onClick={() => setDateRange(dateRange.key)}>{dateRange.value}</MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Tipo de fecha"
                                        name="typeDate"
                                        value={filter.typeDate}
                                        onChange={handleFilterChange}
                                        row
                                    >
                                        {typeDates.map((typeDate) => (
                                            <FormControlLabel value={typeDate.key} control={<Radio />} label={typeDate.value} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2.063}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Box display="flex" alignItems="center" width="100%">
                                <FormControl fullWidth>
                                    <InputLabel>Estado</InputLabel>
                                    <Select
                                        multiple
                                        name="state"
                                        value={filter.state}
                                        onChange={handleFilterChange}
                                    >
                                        {states.map((state) => (
                                            <MenuItem key={state.proposalStateId} value={state.name}>
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Tipo de fecha"
                                        name="quickStatus"
                                        value={filter.quickStatus}
                                        onChange={handleFilterQuickStatusChange}
                                        row
                                    >
                                        {quickStatus.map((typeDate) => (
                                            <FormControlLabel value={typeDate.key} control={<Radio />} label={typeDate.value} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1.55}>
                        <TextField
                            label="Dirección"
                            name="address"
                            value={filter.address}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={1.55}>
                        <TextField
                            label="Nombre de Solicitante"
                            name="ownerName"
                            value={filter.ownerName}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={1.45}>
                        <FormControl fullWidth>
                            <InputLabel>Colaborador</InputLabel>
                            <Select
                                multiple
                                name="collaboratorManager"
                                value={filter.collaboratorManager}
                                onChange={handleFilterChange}
                            >
                                {collaboratorManagers.map((collaboratorManager) => (
                                    <MenuItem key={collaboratorManager} value={collaboratorManager}>
                                        {collaboratorManager}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1.037}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ height: 56, minWidth: 0, marginRight: 1 }}
                            onClick={restartFilter}
                        >
                            <RestartAltOutlined />
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </LocalizationProvider>
    );
};

export default ProposalsFilter;
