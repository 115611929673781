import { useContext, useMemo, useState } from 'react';
import { subMonths, startOfDay, startOfMonth, endOfMonth, subQuarters, subYears, subWeeks, startOfYear, startOfQuarter } from 'date-fns';
import { AppContext } from '../context/appContext';


export const typeDates = [
    { key: 0, value: 'Fecha entrada' },
    { key: 1, value: 'Fecha firmada' }
];

export const quickStatus = [
    { key: 0, value: 'Gestionando' },
    { key: 1, value: 'Todas' }
];

export const dateRanges = [
    { key: '7days', value: 'Últimos 7 días' },
    { key: '14days', value: 'Últimos 14 días' },
    { key: '30days', value: 'Últimos 30 días' },
    { key: '90days', value: 'Últimos 90 días' },
    { key: '12months', value: 'Últimos 12 meses' },
    { key: 'currentMonth', value: 'Mes en curso' },
    { key: 'previousMonth', value: 'Mes anterior' },
    { key: 'currentQuarter', value: 'Trimestre en curso' },
    { key: 'previousQuarter', value: 'Trimestre anterior' },
    { key: 'currentSemester', value: 'Semestre en curso' },
    { key: 'previousSemester', value: 'Semestre anterior' },
    { key: 'currentYear', value: 'Año en curso' },
    { key: 'previousYear', value: 'Año anterior' }
];

export const startOfSemester = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    return month < 6 ? new Date(year, 0, 1) : new Date(year, 6, 1);
};

const DEFAULT_FILTER = {
    fileNum: null,
    initDate: null,
    endDate: null,
    typeDate: 0,
    state: [],
    address: '',
    ownerName: '',
    collaboratorManager: [],
    quickStatus: "0",
};

const notLiveStates = ["FIRMADO", "RECHAZADO"]

const useFilter = () => {
    const { proposalsRealState, handleFilterProposals, states } =
        useContext(AppContext);

    const initialFilter = {
        ...DEFAULT_FILTER,
        state: [...states?.map((state) => state?.name)?.filter(state => !notLiveStates.includes(state))]
    }

    const proposalsFileNum = useMemo(() => {
        return proposalsRealState.map((proposal) => proposal.fileNum.toString());
    }, [proposalsRealState]);

    const collaboratorManagers = useMemo(() => {
        const aditionalTexts = proposalsRealState
            .map(proposal => proposal?.aditionalText?.trim())
            .filter(text => !text?.lenght);
        const distinctAditionalTexts = [...new Set(aditionalTexts)];
        return distinctAditionalTexts;
    }, [proposalsRealState]);

    const [filter, setFilter] = useState(initialFilter);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        const newFilter = {
            ...filter,
            [name]: value,
        };
        setFilter(newFilter);
        handleFilterProposals(newFilter)
    };

    const handleFilterQuickStatusChange = (event) => {
        const { name, value } = event.target;
        const newFilter = {
            ...filter,
            [name]: value,
        };
        switch (value) {
            case "0":
                newFilter.state = [...initialFilter.state]
                break;
            default:
                newFilter.state = []

        }
        setFilter(newFilter);
        handleFilterProposals(newFilter)
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const setDateRange = (range) => {
        const today = startOfDay(new Date());
        const newFilter = { ...filter };
        switch (range) {
            case '7days':
                newFilter.initDate = subWeeks(today, 1);
                newFilter.endDate = today;
                break;
            case '14days':
                newFilter.initDate = subWeeks(today, 2);
                newFilter.endDate = today;
                break;
            case '30days':
                newFilter.initDate = subMonths(today, 1);
                newFilter.endDate = today;
                break;
            case '90days':
                newFilter.initDate = subMonths(today, 3);
                newFilter.endDate = today;
                break;
            case '12months':
                newFilter.initDate = subMonths(today, 12);
                newFilter.endDate = today;
                break;
            case 'currentMonth':
                newFilter.initDate = startOfMonth(today);
                newFilter.endDate = endOfMonth(today);
                break;
            case 'previousMonth':
                newFilter.initDate = startOfMonth(subMonths(today, 1));
                newFilter.endDate = endOfMonth(subMonths(today, 1));
                break;
            case 'currentQuarter':
                newFilter.initDate = startOfQuarter(today);
                newFilter.endDate = today;
                break;
            case 'previousQuarter':
                newFilter.initDate = startOfQuarter(subQuarters(today, 1));
                newFilter.endDate = endOfMonth(subMonths(today, 1));
                break;
            case 'currentSemester':
                newFilter.initDate = startOfSemester(today);
                newFilter.endDate = today;
                break;
            case 'previousSemester':
                newFilter.initDate = startOfSemester(subMonths(today, 6));
                newFilter.endDate = endOfMonth(subMonths(today, 6));
                break;
            case 'currentYear':
                newFilter.initDate = startOfYear(today);
                newFilter.endDate = today;
                break;
            case 'previousYear':
                newFilter.initDate = startOfYear(subYears(today, 1));
                newFilter.endDate = endOfMonth(subMonths(today, 12));
                break;
            default:
                newFilter.initDate = null;
                newFilter.endDate = null;
        }
        handleCloseMenu();
        setFilter(newFilter);
        handleFilterProposals(newFilter)
    };

    const restartFilter = () => {
        setFilter(initialFilter);
        handleFilterProposals(initialFilter);
    };

    const applyFilter = () => {
        handleFilterProposals(filter);
    };

    return {
        filter,
        anchorEl,
        proposalsFileNum,
        collaboratorManagers,
        states,
        handleFilterChange,
        handleFilterQuickStatusChange,
        setDateRange,
        handleOpenMenu,
        handleCloseMenu,
        restartFilter,
        applyFilter,
    };
};

export default useFilter;
