/* eslint-disable jsx-a11y/alt-text */
// Hooks ==========================================================
import React, { useState, useEffect } from "react";
// ================================================================

// Context ==========================================================
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
// ================================================================

// Resources ======================================================
import Login_succeded from "../../assets/images/Login_succeded.png";
import waIcon from "../../assets/images/whatsapp-icon.svg";
import avatarGestor from "../../assets/images/avatar_ejemplo.png";
import { ContactPhone, Drafts, FindInPage, Logout } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import "./index.css";
import SendIcon from "@mui/icons-material/Send";

// ================================================================

// Componentes ====================================================
import { SendButton } from "../SendButton";
import Modal from "../Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProposalsList from "../ProposalsList";
import ProposalsListRealState from "../ProposalsListRealState";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import ManagerInfo from "../ManagerInfo";
// ================================================================

const Dashboard = ({ setLogged }) => {
  let params = useParams();

  function toTitleCase(str) {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    );
  }
  // State ***********************************************************
  const {
    adminData,
    userData,
    firstTime,
    missingFiles,
    setFirstTime,
    isAdmin,
    handleOpenProposalList,
    openProposalList,
    handleSelectProposal,
    proposals,
    handleCloseProposalList,
    isRealState,
    proposalsRealStateFiltered,
    newProposalLink,
    handleChangeProposalData,
    setUserData,
    setAdminData,
    realStateLogo,
    setRealStateLink,
  } = useContext(AppContext);
  const [estadoModal, setEstadoModal] = useState(true);
  const [saludo, setSaludo] = useState("");

  const [showManagerModal, setShowManagerModal] = useState(false);

  const navigate = useNavigate();
  // *****************************************************************
  // Functions ***********************************************************

  useEffect(() => {
    (async () => {
      if (params.fileNum) {
        await handleSelectProposal(parseInt(params.fileNum));
        handleCloseProposalList();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileNum, proposals]);

  const handleLogout = () => {
    setLogged(false);
    setRealStateLink(null);
    localStorage.removeItem("ch_user_token");
    localStorage.removeItem("chUserData");
    localStorage.removeItem("proposalSelected");
    setUserData(null);
    setAdminData(null);

    if (isRealState) navigate("/colaborador");
    else if (isAdmin) navigate("/admin");
    else navigate("/login");
  };

  useEffect(() => {
    let fecha = new Date();
    let hora = fecha.getHours();

    if (hora >= 0 && hora < 12) {
      setSaludo("Buenos Días");
    }

    if (hora >= 12 && hora < 18) {
      setSaludo("Buenas Tardes");
    }

    if (hora >= 18 && hora < 24) {
      setSaludo("Buenas Noches");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX ***********************************************************
  return (
    <>
      {
        // tailwind: create a div with ABSOLUTE and posisionate in the right top corner
        showManagerModal && (
          <Modal
            estado={showManagerModal}
            cambiarEstado={setShowManagerModal}
            titulo="Login Correcto"
            mostrarHeader={false}
            mostrarOverlay={true}
            posicionModal={"center"}
            padding={"40px"}
          >
            <ManagerInfo
              adminData={adminData}
              userData={userData}
            ></ManagerInfo>
          </Modal>
        )
      }
      {openProposalList && (
        <>
          <ProposalsList
            title="Selecciona una propuesta"
            open={openProposalList}
            closable={true}
            handleSelectProposal={handleSelectProposal}
            proposals={proposals}
            handleCloseProposalList={handleCloseProposalList}
            handleLogout={handleLogout}
          />
        </>
      )}

      {(userData?.fileNum || isRealState) && (
        <div>
          <div className="banner">
            <div className="column">
              <Chip
                color="primary"
                avatar={<Avatar />}
                label={
                  isRealState ? userData?.name || userData?.user || adminData?.name || "" :
                    isAdmin
                      ? adminData?.name
                      : userData?.user || adminData?.name || ""
                }
              />
            </div>

            {isAdmin && (
              <div className="column" onClick={() => handleOpenProposalList()}>
                <Chip
                  style={{ cursor: "pointer" }}
                  color="primary"
                  avatar={<Avatar />}
                  label={
                    "Propuesta: " +
                    userData.fileNum +
                    " - " +
                    (userData.nickname ? userData.nickname : userData.user)
                  }
                />
              </div>
            )}
            {realStateLogo && (
              <div className="column">
                <div
                  style={{
                    backgroundImage: `url(${realStateLogo})`,
                    width: 200,
                    height: 90,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                />
              </div>
            )}

            {isRealState && (
              <div
                onClick={() => {
                  setShowManagerModal(!showManagerModal);
                }}
                className="button-back-button"
              >
                <ContactPhone sx={{ fontSize: 50, color: "white" }} />
              </div>
            )}

            <button onClick={handleLogout} className="button-back-button">
              <Logout /> Cerrar Sesión
            </button>
          </div>
          {firstTime && (
            <Modal
              estado={estadoModal}
              cambiarEstado={setEstadoModal}
              titulo="Login Correcto"
              mostrarHeader={false}
              mostrarOverlay={true}
              posicionModal={"center"}
              padding={"40px"}
            >
              <div className="container_modal_image">
                <img src={Login_succeded} alt="logo" />
              </div>
              <div className="container_modal_title">
                <h1 className="modal_title">
                  Bienvenido a Central Hipotecaria
                </h1>
              </div>
              <div className="container_modal_title">
                <h3>
                  Ha ingresado con el usuario:{" "}
                  {userData.nickname || userData?.name}
                </h3>
              </div>
              <div className="container_modal_btn">
                <SendButton
                  className="btn_modal"
                  backgroundColor="#005798"
                  text="Continuar"
                  tƒextColor="white"
                  handleButton={() => setEstadoModal(false)}
                ></SendButton>
              </div>
            </Modal>
          )}

          {isRealState ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mb: 2,
                }}
              >
                <a
                  target="_blank"
                  href={newProposalLink}
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    size="large"
                  >
                    Nueva propuesta
                  </Button>
                </a>
              </Box>

              <div className="container-real-state">
                <ProposalsListRealState
                  proposalsRealState={proposalsRealStateFiltered}
                  handleChangeProposalData={handleChangeProposalData}
                />
              </div>
            </>
          ) : (
            <div className="container">
              <div className="card">
                <div className="container-img">
                  <span>Solicitud</span>
                  <Drafts sx={{ fontSize: 50, color: "white" }} />
                </div>

                <div className="container-text">
                  <h4 className="subtitle">Nº de expediente</h4>
                  <input
                    value={userData.fileNum ?? ""}
                    type="text"
                    placeholder="N de expediente"
                    className="input_solicitud"
                    readOnly
                  ></input>
                  <h4>Tipo de Operación</h4>
                  <input
                    value={toTitleCase(userData.opType)}
                    type="text"
                    placeholder="Tipo de Operación"
                    className="input_solicitud"
                    readOnly
                  ></input>
                  <h4>Importe</h4>
                  <input
                    value={userData.amount}
                    type="text"
                    placeholder="Importe"
                    className="input_solicitud"
                    readOnly
                  ></input>
                  <h4>Estado de la propuesta</h4>
                  <input
                    value={toTitleCase(userData.currentState) ?? ""}
                    type="text"
                    placeholder="Estado Actual"
                    className="input_currentState"
                    readOnly
                  ></input>
                </div>
              </div>
              <div className="card center-title doc">
                <div className="container-img">
                  <span>Documentación</span>
                  <FindInPage sx={{ fontSize: 50, color: "white" }} />
                </div>
                <div className="container-text">
                  <h4>Estado de la revisión</h4>

                  <h4 style={{ marginTop: "30px", marginBottom: "65px" }}>
                    Quedan <br />{" "}
                    <span style={{ color: "red" }}>{missingFiles}</span> <br />
                    documentos pendientes de subir
                  </h4>
                  <Link to="/documents">
                    <button
                      onClick={() => setFirstTime(false)}
                      className="btn_doc"
                    >
                      Gestionar
                    </button>
                  </Link>
                </div>
              </div>

              {adminData?.superAdmin === 0 ? null : (
                <div className="card center-title doc shadow-md cursor-pointer w-350 h-540 mb-20 ml-50 mr-50 text-main-bg-color no-underline rounded-10 transition duration-150 ease-in-out hover:border-blue-500">
                  <ManagerInfo adminData={adminData} userData={userData} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
  // *****************************************************************
};

export default Dashboard;
